import React from 'react';
import QuantWebsite from './components/QuantWebsite';

function App() {
  return (
    <div className="App">
      <QuantWebsite />
    </div>
  );
}

export default App;
