import React, { useState, useEffect, useCallback } from 'react';
import { Globe2, TrendingUp, Search, BookOpen, Users, Code, Moon, Sun } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";

// 优化后的打字效果组件
const TypewriterText = ({ text, speed = 50, isDark = true }) => {
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    // 文本变化时重置状态
    setDisplayText('');
    setCurrentIndex(0);
    setIsTyping(true);
  }, [text]);

  useEffect(() => {
    let timer;

    if (isTyping) {
      if (currentIndex < text.length) {
        timer = setTimeout(() => {
          setDisplayText(prev => prev + text[currentIndex]);
          setCurrentIndex(prev => prev + 1);
        }, speed);
      } else {
        timer = setTimeout(() => {
          setIsTyping(false);
          setCurrentIndex(text.length - 1);
        }, 2000);
      }
    } else {
      if (currentIndex >= 0) {
        timer = setTimeout(() => {
          setDisplayText(prev => prev.slice(0, -1));
          setCurrentIndex(prev => prev - 1);
        }, speed / 2);
      } else {
        timer = setTimeout(() => {
          setIsTyping(true);
          setCurrentIndex(0);
        }, 1000);
      }
    }

    return () => clearTimeout(timer);
  }, [currentIndex, text, speed, isTyping]);

  return (
    <span className="inline-block">
      <span className={`transition-opacity duration-300 ${
        displayText ? 'opacity-100' : 'opacity-0'
      }`}>
        {displayText}
      </span>
      <span className={`
        inline-block w-[3px] ml-1 h-[1.2em] relative top-[0.1em]
        ${isDark ? 'bg-orange-400' : 'bg-orange-500'}
        animate-[cursor-blink_1s_infinite]
      `} />
    </span>
  );
};

// 增强的粒子背景组件
const ParticleBackground = () => {
  const drawParticles = useCallback((ctx, particles, isDark, mousePosition) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    particles.forEach((particle, index) => {
      // 更新粒子位置
      particle.update(mousePosition);

      // 绘制粒子
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fillStyle = isDark ? `rgba(255, 255, 255, ${particle.opacity})` : `rgba(0, 0, 150, ${particle.opacity})`;
      ctx.fill();

      // 绘制连接线
      particles.slice(index + 1).forEach(otherParticle => {
        const dx = particle.x - otherParticle.x;
        const dy = particle.y - otherParticle.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 150) {
          ctx.beginPath();
          ctx.strokeStyle = isDark 
            ? `rgba(255, 255, 255, ${0.2 * (1 - distance / 150)})` 
            : `rgba(0, 0, 150, ${0.1 * (1 - distance / 150)})`;
          ctx.lineWidth = 0.5;
          ctx.moveTo(particle.x, particle.y);
          ctx.lineTo(otherParticle.x, otherParticle.y);
          ctx.stroke();
        }
      });
    });
  }, []);

  useEffect(() => {
    const canvas = document.getElementById('particle-canvas');
    const ctx = canvas.getContext('2d');
    let particles = [];
    let mousePosition = { x: null, y: null };
    let animationFrameId;

    class Particle {
      constructor(canvas) {
        this.canvas = canvas;
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 2 + 1;
        this.speedX = (Math.random() - 0.5) * 0.5;
        this.speedY = (Math.random() - 0.5) * 0.5;
        this.opacity = Math.random() * 0.5 + 0.2;
      }

      update(mousePosition) {
        this.x += this.speedX;
        this.y += this.speedY;

        // 鼠标交互
        if (mousePosition.x != null && mousePosition.y != null) {
          const dx = mousePosition.x - this.x;
          const dy = mousePosition.y - this.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < 100) {
            this.speedX += dx * 0.0005;
            this.speedY += dy * 0.0005;
          }
        }

        // 边界检查
        if (this.x < 0 || this.x > this.canvas.width) this.speedX *= -1;
        if (this.y < 0 || this.y > this.canvas.height) this.speedY *= -1;

        // 限制速度
        const maxSpeed = 2;
        const currentSpeed = Math.sqrt(this.speedX * this.speedX + this.speedY * this.speedY);
        if (currentSpeed > maxSpeed) {
          this.speedX = (this.speedX / currentSpeed) * maxSpeed;
          this.speedY = (this.speedY / currentSpeed) * maxSpeed;
        }
      }
    }

    const init = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      particles = [];
      
      // 根据屏幕大小调整粒子数量
      const particleCount = Math.min(70, (canvas.width * canvas.height) / 15000);
      
      for (let i = 0; i < particleCount; i++) {
        particles.push(new Particle(canvas));
      }
    };

    const animate = () => {
      drawParticles(
        ctx, 
        particles, 
        document.documentElement.classList.contains('dark'),
        mousePosition
      );
      animationFrameId = requestAnimationFrame(animate);
    };

    // 鼠标事件监听
    const handleMouseMove = (e) => {
      mousePosition.x = e.clientX;
      mousePosition.y = e.clientY;
    };

    const handleMouseLeave = () => {
      mousePosition.x = null;
      mousePosition.y = null;
    };

    const handleResize = () => {
      init();
    };

    window.addEventListener('resize', handleResize);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseleave', handleMouseLeave);

    init();
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseleave', handleMouseLeave);
      cancelAnimationFrame(animationFrameId);
    };
  }, [drawParticles]);

  return (
    <canvas
      id="particle-canvas"
      className="fixed top-0 left-0 w-full h-full"
      style={{ zIndex: 0 }}
    />
  );
};

// 主要网站组件
const QuantWebsite = () => {
  const [currentLang, setCurrentLang] = useState('en'); // 默认英文
  const [showVIPAlert, setShowVIPAlert] = useState(false);
  const [isDark, setIsDark] = useState(true); // 默认暗色主题

  // 初始化暗色主题
  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []); 

  // 添加光标闪烁动画到 Tailwind 配置
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes cursor-blink {
        0%, 100% { opacity: 1; }
        50% { opacity: 0; }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  const content = {
    zh: {
      nav: [
        { text: '首页', link: '#' },
        { text: '舆情分析', link: 'http://news.bossquant.top' },
        { text: '智能选股', link: 'http://q.bossquant.top' },
        { text: '量化教程', link: 'https://space.bilibili.com/48693330' },
        { text: '机构合作', link: '#', isVIP: true },
        { text: '交易策略', link: '#', isVIP: true }
      ],
      vipAlert: {
        title: 'VIP专属功能',
        description: 'VIP会员开放，联系客服微信公众号【大佬量化】',
        button: '我知道了'
      },
      hero: {
        title: '专业量化交易解决方案',
        subtitle: '结合AI技术与金融专业知识，为您提供全方位的量化交易支持'
      },
      services: [
        {
          title: 'AI舆情分析',
          desc: '实时新闻分析，捕捉市场情绪',
          icon: Globe2,
          link: 'http://news.bossquant.top'
        },
        {
          title: '智能选股系统',
          desc: '多维度条件筛选，精准定位优质标的',
          icon: Search,
          link: 'http://q.bossquant.top'
        },
        {
          title: '量化交易策略',
          desc: '专业策略定制，稳健收益保障',
          icon: TrendingUp,
          link: '#',
          isVIP: true
        },
        {
          title: '系统化培训',
          desc: '完整的量化投资课程体系',
          icon: BookOpen,
          link: 'https://space.bilibili.com/48693330'
        },
        {
          title: '机构合作',
          desc: '为券商提供一站式解决方案',
          icon: Users,
          link: 'https://mp.weixin.qq.com/s/mESXSKi5qi5Rp7A_2g8ODw'
        },
        {
          title: '策略定制',
          desc: '个性化策略开发服务',
          icon: Code,
          link: '#',
          isVIP: true
        }
      ],
      footer: {
        copyright: '© 2024 量化专业版. 保留所有权利.'
      },
      theme: {
        switchLang: '切换到英文',
        darkMode: '切换暗色模式',
        lightMode: '切换亮色模式'
      }
    },
    en: {
      nav: [
        { text: 'Home', link: '#' },
        { text: 'Market Sentiment', link: 'http://news.bossquant.top' },
        { text: 'Stock Screening', link: 'http://q.bossquant.top' },
        { text: 'Quant Education', link: 'https://space.bilibili.com/48693330' },
        { text: 'Partnership', link: '#', isVIP: true },
        { text: 'Trading Strategies', link: '#', isVIP: true }
      ],
      vipAlert: {
        title: 'VIP Feature',
        description: 'VIP members are available, please contact customer service WeChat official account【bossquant】',
        button: 'Got it'
      },
      hero: {
        title: 'Professional Quantitative Trading Solutions',
        subtitle: 'Combining AI Technology with Financial Expertise for Comprehensive Trading Support'
      },
      services: [
        {
          title: 'AI Sentiment Analysis',
          desc: 'Real-time news analysis for market sentiment tracking',
          icon: Globe2,
          link: 'http://news.bossquant.top'
        },
        {
          title: 'Smart Stock Screening',
          desc: 'Multi-dimensional screening for precise stock selection',
          icon: Search,
          link: 'http://q.bossquant.top'
        },
        {
          title: 'Quantitative Strategies',
          desc: 'Professional strategy customization with stable returns',
          icon: TrendingUp,
          link: '#',
          isVIP: true
        },
        {
          title: 'Systematic Training',
          desc: 'Comprehensive quantitative investment education system',
          icon: BookOpen,
          link: 'https://space.bilibili.com/48693330'
        },
        {
          title: 'Institutional Services',
          desc: 'One-stop solutions for brokerage firms',
          icon: Users,
          link: 'https://mp.weixin.qq.com/s/mESXSKi5qi5Rp7A_2g8ODw'
        },
        {
          title: 'Strategy Development',
          desc: 'Personalized trading strategy development',
          icon: Code,
          link: '#',
          isVIP: true
        }
      ],
      footer: {
        copyright: '© 2024 QuantPro. All rights reserved.'
      },
      theme: {
        switchLang: 'Switch to Chinese',
        darkMode: 'Switch to Dark Mode',
        lightMode: 'Switch to Light Mode'
      }
    }
  };

  const handleNavClick = (item, e) => {
    if (item.isVIP) {
      e.preventDefault();
      setShowVIPAlert(true);
    }
  };
  
  return (
    <div className={`min-h-screen transition-all duration-500 ${isDark ? 'dark bg-gray-900' : 'bg-gray-50'}`}>
      <ParticleBackground />
      
      <nav className={`relative z-10 ${isDark ? 'dark:bg-gray-800/80 backdrop-blur-md' : 'bg-white/80 backdrop-blur-md'} shadow-lg transition-all duration-500`}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center transform hover:scale-105 transition-transform duration-300">
              <TrendingUp className={`h-8 w-8 ${isDark ? 'text-blue-400' : 'text-blue-600'}`} />
              <span className={`ml-2 text-xl font-bold ${isDark ? 'text-white' : 'text-gray-900'}`}>QuantPro</span>
            </div>
            <div className="hidden md:flex space-x-8">
              {content[currentLang].nav.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  target={item.link !== '#' ? '_blank' : ''}
                  rel={item.link !== '#' ? 'noopener noreferrer' : ''}
                  onClick={(e) => handleNavClick(item, e)}
                  className={`${isDark ? 'text-gray-300 hover:text-blue-400' : 'text-gray-700 hover:text-blue-600'} 
                    px-3 py-2 text-sm font-medium transition-all duration-300 hover:scale-105 transform`}
                >
                  {item.text}
                </a>
              ))}
            </div>
            
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setIsDark(!isDark)}
                className={`p-2 rounded-lg transform hover:scale-110 transition-all duration-300 
                  ${isDark ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}
                title={isDark ? content[currentLang].theme.lightMode : content[currentLang].theme.darkMode}
              >
                {isDark ? <Sun size={20} /> : <Moon size={20} />}
              </button>
              <button
                onClick={() => setCurrentLang(currentLang === 'zh' ? 'en' : 'zh')}
                className={`px-4 py-2 text-sm font-medium transform hover:scale-105 transition-all duration-300
                  ${isDark ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}`}
                title={content[currentLang].theme.switchLang}
              >
                {currentLang === 'zh' ? 'EN' : '中文'}
              </button>
            </div>
          </div>
        </div>
      </nav>

      <AlertDialog open={showVIPAlert} onOpenChange={setShowVIPAlert}>
        <AlertDialogContent className={`transform transition-all duration-300 ${isDark ? 'dark:bg-gray-800 dark:text-white' : ''}`}>
          <AlertDialogHeader>
            <AlertDialogTitle>{content[currentLang].vipAlert.title}</AlertDialogTitle>
            <AlertDialogDescription className={isDark ? 'dark:text-gray-300' : ''}>
              {content[currentLang].vipAlert.description}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setShowVIPAlert(false)}>
              {content[currentLang].vipAlert.button}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <div className={`relative z-10 ${isDark ? 'dark:bg-gray-800/50' : 'bg-white/50'} transition-all duration-500`}>
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className={`text-4xl font-extrabold ${isDark ? 'text-white' : 'text-gray-900'} 
              sm:text-5xl sm:tracking-tight lg:text-6xl transition-all duration-500`}>
              <TypewriterText 
                text={content[currentLang].hero.title} 
                isDark={isDark}
                speed={50}
              />
            </h1>
            <p className={`mt-5 max-w-xl mx-auto text-xl ${isDark ? 'text-gray-300' : 'text-gray-500'} 
              transition-all duration-500`}>
              {content[currentLang].hero.subtitle}
            </p>
          </div>
        </div>
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {content[currentLang].services.map((service, index) => (
            <a
              key={index}
              href={service.link}
              onClick={(e) => service.isVIP && handleNavClick(service, e)}
              target={service.link !== '#' ? '_blank' : ''}
              rel={service.link !== '#' ? 'noopener noreferrer' : ''}
              className={`block group cursor-pointer ${
                isDark ? 'dark:bg-gray-800/80 hover:dark:bg-gray-700' : 'bg-white/80 hover:shadow-xl'
              } rounded-lg shadow-lg p-6 transform hover:scale-105 transition-all duration-300 backdrop-blur-sm`}
            >
              <service.icon className={`h-12 w-12 ${isDark ? 'text-blue-400' : 'text-blue-600'} mb-4 
                transition-transform group-hover:scale-110 duration-300`} />
              <h3 className={`text-lg font-semibold ${isDark ? 'text-white' : 'text-gray-900'} mb-2 
                group-hover:text-blue-400 transition-colors duration-300`}>
                {service.title}
                {service.isVIP && (
                  <span className={`ml-2 inline-block px-2 py-1 text-xs rounded-full 
                    ${isDark ? 'bg-blue-400/20 text-blue-300' : 'bg-blue-100 text-blue-600'}`}>
                    VIP
                  </span>
                )}
              </h3>
              <p className={`${isDark ? 'text-gray-300' : 'text-gray-600'} 
                group-hover:text-opacity-80 transition-colors duration-300`}>
                {service.desc}
              </p>
            </a>
          ))}
        </div>
      </div>

      <footer className={`relative z-10 ${isDark ? 'dark:bg-gray-800/80' : 'bg-white/80'} mt-12 transition-all duration-500 backdrop-blur-sm`}>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className={`border-t ${isDark ? 'border-gray-700' : 'border-gray-200'} pt-8`}>
            <p className={`text-center ${isDark ? 'text-gray-500' : 'text-gray-400'} text-sm`}>
              {content[currentLang].footer.copyright}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default QuantWebsite;
